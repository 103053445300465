import Link from 'next/link';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-top">
                <div className="footer-left">
                    <div className="mobile-apps">
                        <p>Descargate las aplicaciones móviles:</p>
                        <div className="mobile-apps-links">
                            <a href="https://apps.apple.com/uy/app/auf-tv/id1472435717">
                                <img
                                    src="/assets/apple_appstore_b.webp"
                                    alt="Descargar aplicación móvil de AUFTV en Apple App Store"
                                />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=tv.auf">
                                <img
                                    src="/assets/google_playstore_b.png"
                                    alt="Descargar aplicación móvil de AUFTV en Google Play"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-right">
                    <span>SEGUINOS EN:</span>
                    <div className="social">
                        <a href="https://twitter.com/auftvoficial">
                            <img
                                src="/assets/tw.svg"
                                alt="Ir a cuenta de Twitter"
                            />
                        </a>
                        <a href="https://www.facebook.com/auftvoficial">
                            <img
                                src="/assets/fb.svg"
                                alt="Ir a cuenta de Facebook"
                            />
                        </a>
                        <a href="https://www.instagram.com/auftvoficial/">
                            <img
                                src="/assets/ig.svg"
                                alt="Ir a cuenta de Instagram"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <Link href="/">
                    <img src="/assets/logo_auftv_b.png" alt="logo AUF TV" />
                </Link>

                <div className="footer-text">
                    {/* <a href="/">Términos de Uso y política de privacidad</a> */}
                    <p>
                        © 2021{' '}
                        <a href="https://auf.org.uy">
                            Asociación Uruguaya de Fútbol
                        </a>
                    </p>
                    <p className="powered">
                        Powered by <a href="http://poipes.io">Poipes</a>
                    </p>
                    {/* <p>Google Play and the Google Play logo are trademarks of Google LLC.</p> */}
                </div>
            </div>
        </div>
    );
};

export { Footer };
