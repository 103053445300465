import Layout from '@/components/Layout';
import Link from 'next/link';
import { getSession } from 'next-auth/react';

export default function LandingPage() {
    return (
        <>
            <div suppressHydrationWarning={true} className="landing-page">
                <div className="hero-landing">
                    <video id="background-video" loop autoPlay muted>
                        <source src="intro.mp4" type="video/mp4" />
                        <source src="intro.webm" type="video/webm" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="hero-claim">
                        <h2>
                            La plataforma de contenidos oficial del fútbol
                            uruguayo
                        </h2>
                        <h3>
                            Selección, Campeonato Uruguayo, VAR, Fútbol
                            Femenino, Playa, Sala, eSports, y mucho más.
                        </h3>
                        <div className="hero-login">
                            <p className="secondary-button">
                                <Link href="/signin">
                                    Ingresá con tu cuenta
                                </Link>
                            </p>
                            <p> ó </p>
                            <p className="primary-button">
                                <Link href="/signup">Registrate Gratis!</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

LandingPage.getLayout = function getLayout(page) {
    return (
        <Layout title="AUFTV | La plataforma de contenidos oficial del fútbol uruguayo">
            {page}
        </Layout>
    );
};

export async function getServerSideProps(ctx) {
    const session = await getSession(ctx);

    if (session) {
        return {
            redirect: {
                permanent: false,
                destination: '/browse',
            },
            props: { session },
        };
    }

    // Pass data to the page via props
    return { props: { session } };
}
